<template>
	<div class="timetable-page" v-loading='loading' element-loading-text='数据加载中'>
    <div class="timetable-wrapper">
      <div class="timetable-container" id="timetable">
        <div class="timetable-inner">
          <div class="timetable-info">
						<div class="left" style="display: grid;grid-template-columns: repeat(3, 1fr)">
							<div v-for="(item, index) in info" :key="index" class="item">
								<span class="label">{{ item.label }}:</span>
                <span class="content">{{ item.content }}</span>
							</div>
						</div>
            <div class="right">
              <div class="date">
                <el-date-picker v-model="date" format='yyyy年-第WW周' placeholder='请选择你的日期' value-format='yyyy-MM-dd' type='week' :picker-options='{ firstDayOfWeek: 1 }' @change="handleChange">
                </el-date-picker>
              </div>
              <div class="bg">
                <div class="block">
                  <div class="lc"></div>
                  <div class="rc"></div>
                </div>
                <div class="circle"></div>
                <div>
                  <div class="line"><div class="dot"></div></div>
                  <div class="line"><div class="dot"></div></div>
                  <div class="line"><div class="dot"></div></div>
                </div>
              </div>
            </div>
          </div>
          <div class="timetable-content">
            <div class="timetable-content-inner" id="scroll">
              <div class="timetable-week">
                <div class="item slash-wrap">
                  <div class="slash"></div>
                  <div class="l">时间</div>
                  <div class="r">日期</div>
                </div>
                <div class="item" v-for="(item, index) in weekData" :key="index">{{ item }}</div>
              </div>
              <div class="timetable-course">
                <div class="line" v-for="(item, index) in sectionData" :key="item.name">
                  <div class="item">
                    <div>{{ item.section_name }}</div>
                    <div v-if="show && item.start_time">{{item.start_time}}-{{item.end_time}}</div>
                  </div>
                  <div class="item" v-for="(child, idx) in courseData[index]" :key="idx">
                    <div class="inner" v-for="(third, cIdx) in child.list" :key="cIdx">
                      <div v-if="!show">{{ third.start_time }}-{{third.end_time}}</div>
                      <div>{{ third.calendar_name || third.course_name }}</div>
                      <div>{{ third.address }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="timetable-footer">
        <el-button type="primary" @click='() => this.$router.back()'>返回列表</el-button>
        <el-button @click="handleDown" v-if="$route.path.includes('teacher')">下载课表</el-button>
      </div>
    </div>
	</div>
</template>

<script>
import {teacherPlanDetail} from "./api";
import {formatDate} from "@/utils";
import {saveImage} from "@/utils/html2canvas";

export default {
  _config:{"route":{"path":"detail","meta":{"title":"查看"}}},
  data() {
    return {
      info: [],
      weekArr: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
      weekData: [],
      sectionData: [],
      courseData: [],
      loading: false,
      date: '',
      show: true,
      name: ''
    };
  },
  created() {
    this.date = formatDate().date
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      teacherPlanDetail(this.$route.query.id, this.date, 1).then(res => {
        const { course_data, max_section, details, time_cycle } = res.data.data
        const arr = time_cycle.slice(1)
        let list = []
        arr.forEach((item, i) => {
          list.push(`${item} ${this.weekArr[i]}`)
        });
        const name = details.period_name
        this.name = name
        this.info = [
          { label: '学生姓名', content: details.student_name },
          { label: '年级', content: details.grade_name },
          { label: '行政班班级', content: details.class_name },
          {label: "校区", content: details.school_name},
          {label: "学习方向", content: details.learning_direction},
          {label: "专业方向", content: details.major},
        ]
        this.weekData = list
        this.sectionData = Object.keys(max_section).map(key => max_section[key])
        this.courseData = this.createTableData([...this.sectionData])
        const keyArr = { 周一: 0, 周二: 1, 周三: 2, 周四: 3, 周五: 4, 周六: 5, 周日: 6 }
        Object.keys(course_data).forEach((key) => {
          const child = course_data[key]
          Object.keys(child).forEach((cKey) => {
            const idx = keyArr[key] ?? key
            const cItem = child[cKey]
            this.courseData[cKey - 1][idx].list = cItem
          })
        })


      }).finally(() => {
        this.loading = false
      })
    },
    createTableData(arr) {
      return arr.map((item) => {
        return new Array(7).fill(0).map((_, j) => ({ ...item, week: this.weekArr[j], list: [] }))
      })
    },
    handleChange(e) {
      this.date = e
      this.getData()
    },
    handleDown() {
      saveImage({ id: 'timetable', scroll: 'scroll', name: this.name })
    }
  },
};
</script>

<style lang='scss' scoped>
.timetable {
  &-page {
    background-color: #F9FAFD !important;
    padding: 20rem 24rem;
  }
  &-wrapper {
    height: calc(100vh - 140rem);
    padding: 20rem 24rem 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 8rem;
  }
  &-container {
    min-width: 1200rem;
    padding: 24rem 0;
    background-color: #5D5AF8;
    // box-shadow: -3rem 0 0 rgba($color: #000, $alpha: .8), 3rem 0 0 rgba($color: #000, $alpha: .8), 0 -3rem 0 rgba($color: #000, $alpha: .8), 0 3rem 0 rgba($color: #000, $alpha: .8);
  }
  &-inner {
    padding: 16rem 24rem 10rem;
    background-color: #F6F9FF;
    border-radius: 16rem;
    display: flex;
    flex-direction: column;
  }
  &-footer {
    height: 80rem;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  &-info {
    height: 80rem;
    margin-bottom: 24rem;
    display: flex;
    justify-content: space-between;
    .left {
      max-width: 940rem;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 40rem;
        position: relative;
        padding: 10rem 40rem 15rem 8rem;
        color: #333;
        font-size: 16rem;
        line-height: 20rem;
        &::before {
          content: '';
          position: absolute;
          bottom: 4rem;
          left: 0;
          right: -8rem;
          height: 12rem;
          background-color: #FFAB00;
          border-radius: 6rem;
        }
        .label {
          position: relative;
          margin-right: 20rem;
        }
        .content {
          position: relative;
          color: #1E3AA2;
          font-weight: 600;
        }
      }
    }
    .right {
      position: relative;
      margin-left: 10rem;
      .date {
        padding: 10rem;
        margin-right: 280rem;
        background-color: #A19FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8rem;
      }
      .bg {
        position: absolute;
        top: -16rem;
        right: -24rem;
        width: 266rem;
        height: 100rem;
        .block {
          position: absolute;
          top: 0;
          right: 0;
          width: 250rem;
          height: 50rem;
          background-color: #5D5AF8;
          border-bottom-left-radius: 30rem;
        }
        .lc {
          position: absolute;
          top: 0;
          left: -30rem;
          width: 30rem;
          height: 50rem;
          background-color: #5D5AF8;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 30rem;
            height: 50rem;
            background-color: #F6F9FF;
            border-top-right-radius: 26rem;
          }
        }
        .rc {
          position: absolute;
          top: 50rem;
          right: 0;
          width: 50rem;
          height: 50rem;
          background-color: #5D5AF8;
          &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 50rem;
            height: 50rem;
            background-color: #F6F9FF;
            border-top-right-radius: 50rem;
          }
        }
        .circle {
          position: absolute;
          top: 0;
          right: 16rem;
          width: 16rem;
          height: 16rem;
          border-radius: 50%;
          background-color: #F85AA6;
          // box-shadow: -26rem 0 0 0 #7FF85A, -52rem 0 0 0 #F5F85A;
          &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            width: 16rem;
            height: 16rem;
            border-radius: 50%;
          }
          &::before {
            right: 26rem;
            background-color: #7FF85A;
          }
          &::after {
            right: 52rem;
            background-color: #F5F85A;
          }
        }
        .line {
          position: absolute;
          top: 5rem;
          // left: 50rem;
          width: 14rem;
          height: 68rem;
          background-color: #F3974C;
          border-radius: 7rem;
          &:nth-child(1) {
            left: 50rem;
          }
          &:nth-child(2) {
            left: 95rem;
          }
          &:nth-child(3) {
            left: 140rem;
          }
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            width: 14rem;
            height: 40rem;
            background-color: #FFCD58;
            border-radius: 7rem;
          }
          .dot {
            position: absolute;
            bottom: -9rem;
            left: -3rem;
            width: 20rem;
            height: 20rem;
            border-radius: 50%;
            background-color: #5D5AF8;
            box-shadow:  0 0 0 6rem #082A70;
          }
        }
      }
    }
  }
  &-content {
    padding: 5rem 0 5rem 5rem;
    border: solid 8rem #082a70;
    border-radius: 5rem;
    &-inner {
      height: calc(100vh - 436rem);
      padding-right: 5rem;
      overflow-y: auto;
    }
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        margin-right: 2rem;
      }
      &:first-child {
        width: 150rem;
        flex-shrink: 0;
        flex: none;
        background-color: #F5F1FF !important;
      }
    }
  }
  &-week {
    position: sticky;
    top: 0;
    display: flex;
    .item {
      height: 60rem;
      margin-bottom: 2rem;
      font-size: 17rem;
      color: #1E3AA2;
      font-weight: 600;
      background-color: #e8edff;
      border-right: solid 1rem #AAC4FF;
    }
    .slash-wrap {
      overflow: hidden;
      position: relative;
      .l {
        position: absolute;
        left: 12rem;
        bottom: 10rem;
      }
      .r {
        position: absolute;
        right: 12rem;
        top: 10rem;
      }

      /* 斜线 */
      .slash {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        /* 斜边边长 */
        // Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2))
        /* 旋转角度计算公式 */
        //  Math.atan(height / width) * 180 / Math.PI
        // 斜边
        // width: 170rem;
        // height: 1rem;
        // transform: rotate(21.80140948635181deg);
        // 背景
        height: 170rem;
        width: 60rem;
        background-color: #e8edff;
        transform: rotate(-68.19859051364818deg);
        transform-origin: top left;
      }
    }
  }
  &-course {
    flex: 1;
    overflow-y: auto;
    .line {
      display: flex;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      &:nth-child(odd) {
        .item {
          &:nth-child(odd) {
            background-color: #FFF6FD;
          }
          &:nth-child(even) {
            background-color: #FFFDF9;
          }
        }
      }
      &:nth-child(even) {
        .item {
          &:nth-child(odd) {
            background-color: #FFFDF9;
          }
          &:nth-child(even) {
            background-color: #FFF6FD;
          }

        }
      }
    }
    .item {
      min-height: 80rem;
      color: #666;
      font-size: 14rem;
      line-height: 26rem;
      border: solid 2rem #74A2FF;
      border-width: 0 2rem 2rem 0;
      border-radius: 4rem;
    }
    .inner {
      padding: 5rem;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      &:not(:last-child) {
        margin-bottom: 5rem;
      }
    }
  }
}
</style>
